/* Ensure full height for root elements */
html, body, #root {
	height: 100%;
	margin: 0;
	padding: 0;
  }
  
  @media (min-width:1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1400px; }
  }
  
  @media (min-width:1300px) and (max-width:1530px) {
	.iq-email-to-list { overflow-x: scroll; margin: 0 20px; padding-left: 0 !important; }
	.iq-email-to-list .justify-content-between { float: left; width: 900px; }
	.iq-chat-ui li a { padding: 20px 0; }
	.chat-sidebar-channel .avatar, .avatar { margin-right: 10px !important; }
	.chat-sidebar-channel .avatar > img { height: 40px; width: 40px; }
	.chat-sidebar-name > h6 { font-size: 14px; }
	.chat-sidebar-name span { font-size: 12px; }
  }
  
  @media (max-width:1699px) {
	.email-form .select2-container { width: 100% !important; }
	.sign-in-page, .sign-in-detail { height: 100%; }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li a { padding: 28px 30px 28px 0; }
  }
  
  @media (max-width:1399px) {
	.animation-card .an-img .bodymovin { margin-bottom: -28px; }
	.animation-card .an-img { margin-top: 0; }
  }
  
  @media (min-width:1300px) {
	.iq-sidebar-menu .iq-menu .iq-menu-title i { display: none; }
	body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title i { font-size: 20px; display: block; }
	body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu .iq-menu-title i { display: none; transition: all 0.45s ease 0s; }
	body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu .iq-menu-title span { display: block; transition: all 0.45s ease 0s; }
	body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title span { display: none; }
	.sidebar-main .iq-sidebar .iq-submenu { opacity: 0; height: 0; }
	.sidebar-main .iq-sidebar:hover .iq-submenu { opacity: 1; height: auto; }
  
	/* Horizontal Menu Page */
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li { position: relative; }
	.iq-page-menu-horizontal .content-page, .iq-page-menu-horizontal .iq-footer { margin-left: 0; }
	.iq-page-menu-horizontal .iq-sidebar-logo { padding-top: 0; }
	.iq-page-menu-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo { display: block; }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul { display: block; position: absolute; top: 100%; padding: 0; min-width: 200px; background: #fff; z-index: 9; box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06); transition: all 0.45s ease 0s; transform: scale(1, 0); transform-origin: 0 0 0; }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul #sidebar-scrollbar { height: 50vh; }
	.iq-menu-horizontal .iq-sidebar-menu li.menu-open>a, .iq-menu-horizontal .iq-sidebar-menu li:hover >a { color: #0084ff; background: transparent; }
	.iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu { display: block; transition: all 0.45s ease 0s; transform: scale(1, 1); }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right, .iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover .iq-arrow-right { transform: rotate(90deg); }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li li .iq-arrow-right, .iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover li .iq-arrow-right { transform: rotate(0deg); }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li li a .iq-arrow-right { transform: rotate(0); }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul li a { padding: 12px 15px; }
	.iq-menu-horizontal .iq-sidebar-menu li > .iq-submenu.iq-submenu-data { opacity: 0; visibility: hidden; left: 100%; top: 0; transition: transform 0.2s; transform: translateX(-50px); }
	.iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu.iq-submenu-data { opacity: 1; visibility: visible; transition: all 0.45s ease 0s; transform: translateX(0); }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li a:hover, .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active>a, .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open>a { background: transparent; }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li>a::before { display: none; }
  
	/* Sidebar icon with text */
	body.icon-with-text .iq-sidebar { width: 150px; overflow: unset; }
	body.icon-with-text .iq-sidebar #sidebar-scrollbar { overflow: unset !important; }
	body.icon-with-text .content-page { margin-left: 150px; }
	body.icon-with-text.sidebar-main .iq-sidebar { width: 80px; }
	body.icon-with-text.sidebar-main .iq-sidebar:hover, body.icon-with-text.sidebar-main .iq-sidebar:hover .iq-sidebar-logo { width: 180px; }
	body.icon-with-text.sidebar-main .iq-sidebar-logo { width: 80px; }
	body.icon-with-text.sidebar-main .content-page, body.icon-with-text.sidebar-main .iq-footer { margin-left: 80px; }
	body.icon-with-text .iq-sidebar-logo { width: 150px; position: relative; overflow: hidden; height: 74px; z-index: 9; flex-direction: column; }
	body.icon-with-text .iq-sidebar-menu .iq-menu > li > a { display: flex; flex-direction: column; }
	body.icon-with-text .iq-sidebar-menu .iq-menu li > a > i { margin: 0 0 10px }
	body.icon-with-text .iq-sidebar-menu .iq-menu li li > a > i { margin: 0 10px 0 0; }
	body.icon-with-text .iq-sidebar-menu .iq-menu li ul { background: #858afb; position: absolute; display: block; top: 0; left: 100%; padding: 0; min-width: 200px; opacity: 0; visibility: hidden; transition: transform 0.2s; transform: translateX(50px); }
	body.icon-with-text .iq-sidebar-menu .iq-menu li:hover > ul { opacity: 1; visibility: visible; transition: all 0.45s ease 0s; transform: translateX(0); }
	body.icon-with-text .iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul li a { padding-left: 15px; }
	body.icon-with-text .iq-sidebar-menu .iq-menu li a { padding: 15px; }
	body.icon-with-text .iq-footer { margin-left: 150px; }
	body.icon-with-text .iq-sidebar-menu .iq-menu li a .iq-arrow-right { position: absolute; left: auto; right: 0; top: 27px; font-size: 12px; }
	body.icon-with-text .iq-sidebar-menu .iq-menu .iq-submenu li a .iq-arrow-right { top: 15px; }
	body.icon-with-text .iq-sidebar-menu .iq-menu li a[aria-expanded="true"] .iq-arrow-right, body.icon-with-text .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right, body.icon-with-text .iq-sidebar-menu .iq-menu li.active .iq-arrow-right { transform: rotate(0deg); }
	body.icon-with-text .iq-sidebar-logo a { margin: 0 auto; }
  
	/* Top-tab-horizontal */
	.top-tab-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo { display: block; }
	.top-tab-horizontal .iq-sidebar-logo { padding: 0; }
	.top-tab-horizontal .content-page { padding: 80px 15px 0; width: 100%; margin-left: 0; }
	.top-tab-horizontal .iq-footer { margin-left: 0; }
	.tab-menu-horizontal { background: #0084ff; margin-top: 75px; transition: all 0.45s ease 0s; position: fixed; top: 0; left: 0; right: 0; z-index: 9; }
	body.top-tab-horizontal .tab-menu-horizontal.menu-down { top: -60px; transition: all 0.45s ease 0s; opacity: 0; }
	body.top-tab-horizontal .tab-menu-horizontal.menu-up { top: 0; opacity: 1; transition: all 0.45s ease 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu { display: block; position: absolute; top: 150%; background: #ffffff; min-width: 260px; z-index: 9; padding: 0; opacity: 0; visibility: hidden; box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06); transition: all 0.45s ease 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > ul.iq-submenu { opacity: 1; visibility: visible; top: 100%; transition: all 0.45s ease 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu#ui-elements { height: 350px; overflow-y: scroll; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu ul.iq-submenu-data { left: 130%; top: 0; padding: 0; opacity: 0; visibility: hidden; display: block; transition: all 0.45s ease 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu li:hover ul.iq-submenu-data { left: 100%; opacity: 1; visibility: visible; transition: all 0.45s ease 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li { padding: 10px 0; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a { border-radius: 4px; padding: 10px 20px; color: #ffffff; transition: all 0.5s ease-out 0s; }
	.iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > a { color: #0084ff; background: #e5f2ff; transition: all 0.5s ease-out 0s; }
	body.top-tab-horizontal .iq-top-navbar .top-menu { border-radius: 0; margin-bottom: 0; padding: 0; min-height: 73px; position: fixed; top: 0; left: 0; width: 100%; display: inline-block; z-index: 99; background: #ffffff; box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1); }
	body.top-tab-horizontal .iq-top-navbar { position: static; box-shadow: none; }
  }
  
  @media (max-width:1299px) {
	.iq-sidebar-menu .iq-menu .iq-menu-title i { display: none; }
	.iq-circle-progressbar .percent { font-size: 1.3rem !important; }
	.iq-circle-progressbar svg { width: 80px !important; height: 80px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 80px !important; height: 80px !important; }
	.content-page, body.sidebar-main .content-page { margin-left: 0; padding: 100px 0 0; }
	.iq-top-navbar .iq-sidebar-logo .top-logo { display: block; }
	.iq-footer, body.sidebar-main .iq-footer { padding: 15px 10px; margin-left: 0; }
	.iq-sidebar { display: inline-block; z-index: 99; left: -260px; }
	.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb { display: none; }
	.iq-sidebar-logo { width: auto; }
	.iq-top-navbar .iq-navbar-custom .iq-menu-bt { display: inline-block; position: absolute; right: 310px; opacity: 1; }
	body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span { opacity: 1; display: inline-block; }
	body.sidebar-main .iq-sidebar { width: 260px; left: 0; }
	body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span { display: inline-block; opacity: 1; }
	body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right { display: inline-block; }
	.iq-email-to-list ul li { margin: 0 2px 0 0; }
	.an-img-two { width: 600px; top: -118px; }
	.iq-menu-horizontal { position: absolute; left: -100%; right: 0; width: 100%; opacity: 0; top: 100%; background: #fff; height: 80vh; overflow-y: scroll; overflow-x: hidden; transition: all 0.45s ease 0s; }
	.iq-page-menu-horizontal.sidebar-main .iq-menu-horizontal { opacity: 1; left: 0; transition: all 0.45s ease 0s; }
	.iq-sidebar-menu .iq-menu { display: block !important; }
	.iq-menu-horizontal .iq-sidebar-menu .iq-menu li a { padding: 15px 20px 15px 30px; }
	.iq-sidebar-menu .iq-menu li ul { padding-left: 30px; }
	.iq-sidebar-menu .iq-menu li ul.iq-submenu-data { padding-left: 40px; }
	.two-sidebar .content-page { margin-left: 60px; }
	.two-sidebar .iq-footer { margin-left: 60px; }
	body.two-sidebar.sidebar-main .content-page, body.sidebar-main .iq-footer { margin-left: 60px; }
	body.two-sidebar.sidebar-main .iq-sidebar { width: 260px; }
	.two-sidebar .iq-sidebar-logo { width: auto; margin-left: 40px; }
	.tab-menu-horizontal { position: fixed; z-index: 999; top: 75px; left: -260px; width: 260px; overflow: hidden; height: 100vh; overflow-y: scroll; overflow-x: hidden; background: #ffffff; transition: all 0.3s ease-in-out; }
	body.top-tab-horizontal.sidebar-main .tab-menu-horizontal { left: 0; }
	body.top-tab-horizontal .iq-menu-horizontal-tab { width: 100%; }
	.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul li a { padding-left: 60px; }
  }
  
  @media (max-width:1199px) {
	.an-img-two { display: none; }
	.iq-booking-no li .iq-seat { width: 35px; height: 45px; }
	.iq-booking-no li { width: 4.7%; }
	.iq-email-to-list { overflow-x: scroll; margin: 0 20px; padding-left: 0 !important; }
	.iq-email-to-list ul li { margin: 0 5px 0 0; }
	.iq-email-to-list .justify-content-between { float: left; width: 900px; }
	.sign-in-from { padding: 0 30px; }
	.sign-in-detail { padding: 100px 50px; }
  }
  
  @media (min-width:992px) and (max-width:1199px) {
	.iq-chat-ui li a { padding: 20px 0; }
	.chat-sidebar-channel .avatar, .avatar { margin-right: 10px !important; }
	.chat-sidebar-channel .avatar > img { height: 40px; width: 40px; }
	.chat-sidebar-name > h6 { font-size: 14px; }
	.chat-sidebar-name span { font-size: 12px; }
  }
  
  @media (max-width:991px) {
	.an-img-two .bodymovin { display: none; }
	.display-1 { font-size: 4rem; font-weight: 300; }
	.display-2 { font-size: 3.5rem; font-weight: 300; }
	.display-3 { font-size: 3rem; font-weight: 300; }
	.display-4 { font-size: 2.5rem; font-weight: 300; }
	.display-5 { font-size: 2rem; font-weight: 300; }
	.display-6 { font-size: 1.5rem; font-weight: 300; }
	.iq-top-navbar .navbar { position: relative; display: inherit; }
	.iq-top-navbar .navbar-toggler { display: block; right: 85px; top: 18px; position: absolute; color: #0084ff; background: #e5f2ff; border: none; padding: 7px 10px; border-radius: 5px; font-size: 24px; }
	.navbar-collapse { position: absolute; top: 75px; left: 0; width: 100%; background: #fff; box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15); }
	.navbar-nav.navbar-list { float: right; display: inline-block; }
	.iq-top-navbar .iq-navbar-custom .iq-menu-bt { right: 130px; top: 18px; }
	.animation-card .an-img .bodymovin { margin-bottom: 0; margin-left: 0; width: 100%; }
	.iq-footer, .iq-footer .col-lg-6.text-right { text-align: center !important; }
	.iq-booking-index { overflow-x: scroll; }
	.seat-booking { width: 980px; position: relative; }
	.seat-booking .col-sm-1 { flex: 0 0 8.333333%; max-width: 8.333333%; width: 8.333333%; }
	.seat-booking .col-sm-10 { flex: 0 0 83.333333%; max-width: 83.333333%; width: 83.333333%; }
	.iq-booking-offer { padding: 30px; }
	.offer-an-img { position: static; width: 100%; }
	.sign-in-from { padding: 0 20px; }
	.iq-maintenance .col-lg-4 { margin-bottom: 15px; }
	.chat-data-left { position: absolute; left: 0; max-width: 320px; top: 0; z-index: 9; background: #fff; transform: translateX(-100%); opacity: 0; transition: all 0.45s ease 0s; box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06); overflow-y: scroll; height: 100%; }
	.chat-sidebar-channel { overflow: auto; height: auto; padding-left: 0 !important; }
	.chat-search { padding-left: 0 !important; }
	.chat-data-left.show { transform: translateX(0); opacity: 1; transition: all 0.45s ease 0s; }
	button.close-btn-res { display: block; background: transparent; border: none; font-size: 20px; font-weight: 600; position: absolute; top: 10px; left: auto; right: 0; }
	.chat-head header { padding-left: 15px; }
	div#sidebar-toggle { display: block; }
	#sidebar-toggle { background: #e5f2ff; padding: 12px 10px; margin-right: 15px; border-radius: 5px; height: 40px; width: 40px; line-height: 17px; text-align: center; color: #0084ff; }
	#chat-user-detail-popup { overflow-y: scroll; padding-bottom: 20px; }
	#user-detail-popup { overflow: scroll; }
	ul.profile-img-gallary li img { width: 100%; }
	.profile-feed-items li a { margin: 0 5px 0 0; }
	.profile-left { order: 2; }
	.profile-center { order: 1; }
	.profile-right { order: 3; }
	.iq-edit-profile .nav-link { font-size: 14px; }
	.stepwizard-row .wizard-step a { padding: 20px 15px; margin: 10px 10px; }
	.top-tab-horizontal .iq-navbar-custom.d-flex { display: block !important; }
	.iq-page-menu-horizontal .iq-navbar-custom.d-flex { display: block !important; }
  }
  
  @media (max-width:979px) {
	.iq-circle-progressbar .percent { font-size: 2.5rem !important; }
	.iq-circle-progressbar svg { width: 200px !important; height: 200px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 200px !important; height: 200px !important; }
  }
  
  @media (max-width:767px) {
	.table { width: 100%; max-width: 100%; margin-bottom: 1rem; display: block; overflow-x: auto; }
	.display-1 { font-size: 2.5rem; font-weight: 500; }
	.display-2 { font-size: 2.3rem; font-weight: 500; }
	.display-3 { font-size: 2rem; font-weight: 500; }
	.display-4 { font-size: 1.6rem; font-weight: 500; }
	.display-5 { font-size: 1.3rem; font-weight: 500; }
	.display-6 { font-size: 1rem; font-weight: 500; }
	.search-box .search-input { width: 280px; }
	.navbar-nav.navbar-list { display: inline-block; width: 100%; }
	.navbar-nav.navbar-list li { float: right; }
	.sign-info { text-align: center; margin-bottom: 30px; }
	.iq-social-media { width: 100%; }
	.iq-social-media li { float: none; display: inline-block; }
	.sign-in-from button { margin-top: 10px; }
	.sign-in-from .custom-control.custom-checkbox { display: block !important; }
	.sign-in-detail { padding: 50px 20px; }
	.sign-in-from { padding: 20px; }
	.iq-error h1 { font-size: 12rem; }
	.user-detail { margin-bottom: 15px; padding-left: 0 !important; }
	.user-detail .d-flex { display: block !important; text-align: center; }
	.profile-img { text-align: center; padding-right: 0 !important; }
	.profile-feed-items { width: 100%; justify-content: center; }
	.iq-edit-profile .nav-link { border: none; }
	.user-list-files.d-flex.float-right { display: block !important; text-align: center; margin-top: 30px; width: 100%; }
	.table-responsive #exampleInputSearch { width: 100%; }
	.wizard-step { width: 50%; }
	.Products ul.product_list { text-align: center; }
	.iq-image-container { text-align: center; }
	.iq-item-product-right { margin-top: 30px; }
	.ckeckout-product-lists .d-flex { display: block !important; }
	.ckeckout-product { width: 100%; text-align: center; margin-bottom: 20px; }
	.checkout-product-details { text-align: center; }
	.iq-advance-course.d-flex { display: block !important; text-align: center; }
	.iq-advance-course .right-block { margin-top: 15px; }
	.iq-advance-course .left-block .d-flex { display: block !important; }
	.right-block .image-absulute.image-absulute-1 { right: 50px; }
	.right-block .image-absulute.image-absulute-2 { left: 40px; right: auto; top: auto; bottom: 50px; }
	body.iq-page-menu-horizontal .iq-navbar-custom.d-flex { display: block !important; }
  }
  
  @media (max-width:479px) {
	.display-1 { font-size: 2.0rem; font-weight: 500; }
	.display-2 { font-size: 1.8rem; font-weight: 500; }
	.display-3 { font-size: 1.6rem; font-weight: 500; }
	.display-4 { font-size: 1.4rem; font-weight: 500; }
	.display-5 { font-size: 1.2rem; }
	.iq-circle-progressbar .percent { font-size: 2rem !important; }
	.iq-circle-progressbar svg { width: 150px !important; height: 150px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 150px !important; height: 150px !important; }
	.iq-card-body { padding: 15px; }
	.iq-sidebar-logo a span { display: none; }
	body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span { opacity: 0; display: none; }
	.iq-error h1 { font-size: 8rem; }
	.chat-head header { overflow-x: scroll; }
	#chat-user-detail-popup { width: 300px; }
	.profile-feed-items { display: block !important; }
	.profile-feed-items li { margin: 0 0 20px 0; }
	ul.header-nav li { height: 35px; width: 35px; line-height: 35px; margin: 0 5px 0 0; font-size: 16px; }
	.comment-area.p-3 > div { display: block !important; }
	.comment-area.p-3 > div> div { margin-bottom: 10px; }
	.profile-feed-items li a { text-align: center; }
	.wizard-step { width: 100%; }
  }
  
  @media (max-width:359px) { }
  
  /* Remove height restrictions for better screen fit */
  .sign-in-page, .sign-in-detail, .content-page, .iq-footer {
	min-height: 100vh; /* Ensures elements take at least full viewport height */
  }
  
  /* Ensure flexbox layout for full height containers */
  body, .container, .container-lg, .container-md, .container-sm, .container-xl {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
  }
  
  main.content-page {
	flex: 1; /* Allows main content to expand and take available space */
  }
  